.focus-group {
  &:focus-within {
    .focus-item {
      opacity: 1;
      visibility: visible;
    }
  }
  
  .focus-item {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    visibility: hidden;
  }
}

.hover-group {
  &:hover {
    &.hover-highlight,
    & .hover-highlight {
      background-color: var(--bs-sn-clr-light-green);
    }

    .hover-item {
      opacity: 1;
      visibility: visible;
    }
  }
  
  .hover-item {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    visibility: hidden;
  }
}